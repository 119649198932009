import {
  Children,
  cloneElement,
  createElement,
  FC,
  Fragment,
  isValidElement,
  ReactElement,
  ReactNode
} from 'react';

export interface MessagesProps {
  /**
   * Show all that match or just the first one that matches?
   * @default true
   */
  single?: boolean;
  /** The values to match against */
  values: object;
  /** Message Children to match */
  children: ReactNode;
}

export const Messages: FC<MessagesProps> = ({
  values,
  children,
  single = true
}) => {
  const entries = Object.entries(values);
  const kids = Children.toArray(children);
  const predicate = (child: ReactNode) =>
    isValidElement(child) &&
    entries.every(([prop, value]) =>
      prop in child.props ? (child.props as any)[prop] === value : true
    );
  const transform = (elt: ReactElement) =>
    cloneElement(elt, { __values__: values });

  if (single) {
    const child = kids.find(predicate) as ReactElement<any> | undefined;
    return child ? transform(child) : null;
  }
  return (
    <Fragment>
      {(kids.filter(predicate) as ReactElement[]).map(transform)}
    </Fragment>
  );
};

export const Message: FC<any> = ({ children, __values__ }) =>
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  isFunction(children) ? children(__values__) : children || null;

// eslint-disable-next-line @typescript-eslint/ban-types
const isFunction = (value: any): value is Function =>
  typeof value === 'function';
