/* eslint-disable @typescript-eslint/no-use-before-define */

import { TextInput, TextInputProps } from 'legos/text-field/base-input';
import {
  ChangeEvent,
  createElement,
  forwardRef,
  ForwardRefExoticComponent,
  FC,
  ReactNode,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect
} from 'react';
import {
  Field as FFField,
  FieldProps as FFFieldProps,
  FieldRenderProps as FFFieldRenderProps
} from 'react-final-form';
import { Messages } from './messages';
import { pipeValidators, requiredValidator, Validator } from './validators';
import { FormatterConfig, FormatterHook } from 'packages/react-input-formatter';

type ForwardedInputProps = Pick<
  TextInputProps,
  | 'label'
  | 'required'
  | 'readOnly'
  | 'autoComplete'
  | 'type'
  | 'inputMode'
  | 'largeInput'
  | 'showValidationSpacing'
> & { multiMessages?: boolean } & { analyticsEncryptSensitive?: boolean };

interface SupportedFieldProps {
  /**
   * Function to determine if the value is valid. Can return a promise to run async.
   * If value is valid, return `undefined` if invalid return the error key string.
   * https://github.com/final-form/react-final-form#validate-value-any-allvalues-object--any
   */
  validate?: FFFieldProps<HTMLInputElement>['validate'];
  /**
   * Function to determine if 2 different model values are equivalent, i.e. actual Date objects
   * @default '==='
   */
  isEqual?: FFFieldProps<HTMLInputElement>['isEqual'];
  /**
   * Function that is used to format / mask the view value when it is not focused.
   * This value will never be read or validated, only used when the input is blurred.
   * Useful for masking sensitive info only when blurred (i.e. PAN / CVC input)
   */
  format?: FFFieldProps<HTMLInputElement>['format'];
  formatOnBlur?: FFFieldProps<HTMLInputElement>['formatOnBlur'];
  formatPattern?: string;
  formatter?: FormatterHook;
  formatterConfig?: FormatterConfig;
  parse?: FFFieldProps<HTMLInputElement>['parse'];
  ref?: RefObject<HTMLInputElement>;
}

/* Our props are a combo of the above three component props */
export interface TextFieldProps
  extends ForwardedInputProps,
    SupportedFieldProps {
  /** The name of the form field. Should be unique across all fields in the given form */
  name: string;
  /**
   * Function that is used to accept or reject a value from an onChange event.
   * The return should be an object with the view value being `value` and an
   * error value being set to an error key. The error set here will be reflected
   * in the input meta data `changeError` key. If there is no `value` specified,
   * the change will be ignored and no value will be emitted to the form as the
   * "next" value
   */
  allowChange?: Validator<string>;
  children: ReactNode;
  mask?: (value: string) => string;
  disableValidateOnBlur?: boolean;
}

type TextFieldImplProps = FFFieldRenderProps<HTMLInputElement> & {
  forwarded: Pick<
    TextFieldProps,
    Exclude<keyof TextFieldProps, 'isEqual' | 'validate' | 'name'>
  > & {
    ref: RefObject<any>;
    children: ReactNode;
  };
};

const noop = () => {
  /* noop */
};

export const TextField: ForwardRefExoticComponent<TextFieldProps> = forwardRef(
  (
    {
      name,
      isEqual,
      validate,
      required,
      format,
      formatOnBlur,
      formatter,
      formatterConfig,
      ...props
    },
    ref
  ) => {
    const requiredValidation = useMemo<
      FFFieldProps<HTMLInputElement>['validate']
    >(
      () =>
        required
          ? pipeValidators(requiredValidator(), validate || (noop as any))
          : validate,
      [required, validate]
    );

    return (
      <FFField
        name={name}
        isEqual={isEqual}
        validate={requiredValidation}
        formatOnBlur={formatOnBlur}
        forwarded={{ ...props, formatter, formatterConfig, ref, required }}
        component={TextFieldImpl as any}
      />
    );
  }
);

/* Helper util to revert to a previous value and maintain the same caret position */
const rollbackValueAndMaintainCaret = (
  input: HTMLInputElement,
  lastValue: string
) => {
  const lengthDiff = input.value.length - lastValue.length;
  const start = (input.selectionStart || 0) - lengthDiff;
  const end = (input.selectionEnd || 0) - lengthDiff;
  input.value = lastValue;
  input.setSelectionRange(start, end);
};

const TextFieldImpl: FC<TextFieldImplProps> = ({
  input: { value, onChange: ffOnChange, onBlur, ...input },
  meta,
  forwarded: {
    allowChange,
    type,
    label,
    autoComplete,
    required,
    readOnly,
    largeInput,
    inputMode,
    children,
    mask,
    multiMessages = false,
    analyticsEncryptSensitive = false,
    formatter,
    formatterConfig,
    disableValidateOnBlur,
    showValidationSpacing = true,
    ref
  }
}) => {
  const lastValueRef = useRef<string>(value || '');
  const [changeError, setChangeError] = useState<string | void>();

  const canChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): boolean => {
      const nextChangeError = allowChange && allowChange(e.target.value);
      setChangeError(nextChangeError);
      if (nextChangeError) {
        rollbackValueAndMaintainCaret(e.target, lastValueRef.current);
        return false;
      }
      return true;
    },
    [allowChange]
  );

  // We have to override onChange to potentially stop a new value from propagating.
  // This triggers the "warning" state
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (formatter || canChange(e)) {
        lastValueRef.current = e.target.value;
        ffOnChange(e);
      }
    },
    [canChange, ffOnChange, formatter]
  );

  // Whenever the input blurs, remove the change warning
  useEffect(() => {
    if (!meta.active) {
      setChangeError();
    }
  }, [meta.active, setChangeError]);

  // Save the onBlur since we do not want to call onBlur just if onBlur changes
  const onBlurRef = useRef(onBlur);
  onBlurRef.current = onBlur;
  // Should mark as disabled only while the form is submitting
  const disabled = meta.submitting;
  // We should tell FF to blur ourselves if we find ourselves focused while we are in a state that does not support being focused.
  const shouldBeBlurred = meta.active && (readOnly || disabled);
  useEffect(() => {
    if (shouldBeBlurred) {
      onBlurRef.current();
    }
  }, [shouldBeBlurred, onBlurRef]);

  // if a formatter is set, setup the formatOnChange function.
  const formatOnChange = useMemo(
    () =>
      formatter
        ? formatter.setup({
            ...formatterConfig,
            shouldFormat: canChange,
            onChange
          })
        : undefined,
    [canChange, formatter, onChange, formatterConfig]
  );

  // extracts the formatted value from the formatter so that it can be used as
  // a dependency on the getValue function without causing constant rerenders
  const formattedValue = formatter ? formatter.value : undefined;

  /**
   * Fetches the correct value to show in the input.
   * - Shows mask when blurred
   * - Shows formatted value when a formatter is present
   * - Shows the raw field value in all other cases
   */
  const getValue = useCallback((): string => {
    if (!meta.active && mask) {
      return mask(value);
    }
    if (formattedValue) {
      return formattedValue || '';
    }
    return value || '';
  }, [meta.active, mask, value, formattedValue]);

  return (
    <TextInput
      type={type}
      label={label}
      autoComplete={autoComplete}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
      largeInput={largeInput}
      inputMode={inputMode}
      valid={meta.valid || !meta.touched}
      aria-invalid={meta.invalid}
      {...input}
      onBlur={disableValidateOnBlur ? () => true : onBlur}
      onChange={formatter ? formatOnChange : onChange}
      value={getValue()}
      showValidationSpacing={showValidationSpacing}
      analyticsEncryptSensitive={analyticsEncryptSensitive}
      ref={ref}
    >
      <Messages
        single={!multiMessages}
        values={{ ...meta, changeError }}
        children={children as any}
      />
    </TextInput>
  );
};
