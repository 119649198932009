export type Validator<T> = (value: T, allValue?: unknown) => string | void;
export type NamedValidator<T> = Validator<T> & { key: string };

export const pipeValidators = <T>(
  ...validators: Array<Validator<T>>
): Validator<T> => (value: T, allValues: unknown) => {
  let error: string | void;
  for (let i = 0, ii = validators.length; i < ii; i++) {
    error = validators[i](value, allValues);
    if (error) {
      break;
    }
  }
  return error;
};

export const validateAllErrors = <T>(
  ...validators: Array<Validator<T>>
): Validator<T> => (value: T, allValues: unknown) => {
  let error: string | void;
  const errors: string[] = [];
  for (let i = 0, ii = validators.length; i < ii; i++) {
    error = validators[i](value, allValues);
    if (error) {
      errors.push(error);
    }
  }
  return errors.join(',');
};

const makeValidator = <T>(
  name: string,
  test: (value: T, allValue: unknown) => boolean
): NamedValidator<T> => {
  const validator = (value: T, allValue: unknown) =>
    test(value, allValue) ? name : void 0;
  validator.key = name;
  return validator;
};

// All the error keys, used as constants for reference
export const requiredError = 'required';
export const minLengthError = 'min-length';
export const maxLengthError = 'max-length';
export const patternError = 'pattern';
export const digitsError = 'digits';

/**
 * Valid if the value truthy
 */
export const requiredValidator = () =>
  makeValidator<any>(requiredError, (value) => !value);

/**
 * Valid if the value is >= to minLength
 */
export const minLengthValidator = (minLength: number) =>
  makeValidator<string>(
    minLengthError,
    (value) => !!value && value.length < minLength
  );

/**
 * Valid if the value is <= to maxLength
 */
export const maxLengthValidator = (maxLength: number) =>
  makeValidator<string>(
    maxLengthError,
    (value) => !!value && value.length > maxLength
  );

/**
 * Validate the value based on a RegExp. Use this as a last resort since evaluating RegExp
 * on every keystroke can lead to performance issues. Optionally specify a different error
 * key for use with multiple pattern validators that check different things.
 */
export const patternValidator = (
  re: RegExp,
  errorName: string = patternError
) => makeValidator<string>(errorName, (value) => !!value && !re.test(value));

/**
 * Valid if string does not contain any characters NOT 0-9. Note: decimals,
 * commas, etc are considered invalid
 */
export const digitsValidator = () =>
  makeValidator<string>(digitsError, (value) => {
    value = value || '';
    let { length } = value;
    while (--length >= 0) {
      const charCode = value.charCodeAt(length);
      if (charCode < 48 || charCode > 57) {
        return true;
      }
    }
    return false;
  });

/**
 * Invalidate if the value matches the validator
 */
export const invertValidator = <T>(
  errorName: string,
  validator: Validator<T>
): Validator<T> => (value) =>
  validator(value) === undefined ? errorName : undefined;

/**
 * compares the value with the provided name of the form field
 * and returns true if no mismatch
 */
export const mismatchValidator = (compareField: string, errorName: string) =>
  makeValidator<string>(
    errorName,
    (value, allValues) =>
      !!allValues && value !== (allValues as any)[compareField]
  );
